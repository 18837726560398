const initBlock = function( $block ) {
	const $hero = $block;
	const $heroDropdown = $hero.find( '.w24-portal' );

	$heroDropdown.on( 'click', function( e ) {
		const $this = $( this );
		const $target = $( e.target );
		if ( !$target.closest( '.w24-portal__dropdown' ).length ) {
			e.preventDefault();
			$this.toggleClass( 'is-hover' );
		}
	} );
};

themeUtils.loadBlock( initBlock, 'hero', '.section-hero' );
